import {
  Button,
  Collapse,
  Divider,
  Drawer,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import VisitsDurationIcon from '@material-ui/icons/AccessTime';
import SalesDashboardIcon from '@material-ui/icons/AttachMoney';
import FraudDebugIcon from '@material-ui/icons/BugReport';
import DriveoffDashboardIcon from '@material-ui/icons/DirectionsCar';
import InsightsIcon from '@material-ui/icons/DonutLarge';
import CompanyDashboardIcon from '@material-ui/icons/Equalizer';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import OpsDashboardIcon from '@material-ui/icons/LocalCarWash';
import RewashesIcon from '@material-ui/icons/LocalCarWash';
import LocationsIcon from '@material-ui/icons/LocationOn';
import DomainIcon from '@material-ui/icons/Domain';
import LocationIntelligenceIcon from '@material-ui/icons/NotListedLocation';
import OilChangersLocationIntelligenceIcon from '@material-ui/icons/NotListedLocation';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import UsersIcon from '@material-ui/icons/People';
import LicensePlateSearchIcon from '@material-ui/icons/Search';
import ReportsIcon from '@material-ui/icons/Timeline';
import ActiveVisitsIcon from '@material-ui/icons/Timer';
import FraudIcon from '@material-ui/icons/VerifiedUser';
import CameraDashboardIcon from '@material-ui/icons/Videocam';
import Logo from 'components/common/Logo';
import mixpanel from 'components/common/mixpanel';
import PlusLabel from 'components/common/PlusLabel';
import { isWhiteLabel } from 'components/common/whitelabel';
import MenuAppBar from 'components/menu/MenuAppBar';
import ImpersonationStatus from 'components/user/ImpersonationStatus';
import UserInfoNavigationMenu from 'components/user/UserInfoNavigationMenu';
import React, { useEffect, useRef, useState } from 'react';
import { FaCarSide as CarIcon, FaCogs as ApiIcon } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  isActiveVisitsAvailable,
  isCompaniesAvailable,
  isCompanyDashboardAvailable,
  isDriveoffDashboardAvailable,
  isDriveoffDashboardOnly,
  isFraudDebugReportAvailable,
  isFraudReportAvailable,
  isInsightsAvailable,
  isLocationIntelligenceAvailable,
  isLocationIntelligenceOnly,
  isLocationsAvailable,
  isOilAndLocationIntelligenceOnly,
  isOilChangersLocationIntelligenceAvailable,
  isOilLocationIntelligenceOnly,
  isOpsDashboardAvailable,
  isRewashesAvailable,
  isUsersAvailable,
  isVisitDurationAvailable,
} from 'reducers/auth';
import useQueryParam from 'components/common/useQueryParam';
import { useDidMount } from 'react-hooks-lib';

export const DRAWER_WIDTH = 270;

const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  logo: {
    display: 'block',
    margin: `${theme.spacing(2)}px auto`,
    height: 100,
    width: '100%',
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
    overflowX: 'hidden',
  },
  icon: {
    fontSize: 24,
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 2,
  },
  insightsMenuLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  insightsMenuLabel: {
    marginRight: theme.spacing(0.3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

function NavigationDrawer({ classes, location }) {
  const { pathname } = location;
  const insightsAvailable = useSelector(isInsightsAvailable);
  const activeVisitsAvailable = useSelector(isActiveVisitsAvailable);
  const visitDurationAvailable = useSelector(isVisitDurationAvailable);
  const rewashesAvailable = useSelector(isRewashesAvailable);
  const usersAvailable = useSelector(isUsersAvailable);
  const companiesAvailable = useSelector(isCompaniesAvailable);
  const locationsAvailable = useSelector(isLocationsAvailable);
  const fraudReportAvailable = useSelector(isFraudReportAvailable);
  const fraudDebugReportAvailable = useSelector(isFraudDebugReportAvailable);
  const opsDashboardAvailable = useSelector(isOpsDashboardAvailable);
  const driveoffDashboardAvailable = useSelector(isDriveoffDashboardAvailable);
  const driveoffDashboardOnly = useSelector(isDriveoffDashboardOnly);
  const companyDashboardAvailable = useSelector(isCompanyDashboardAvailable);
  const locationIntelligenceAvailable = useSelector(isLocationIntelligenceAvailable);
  const oilChangersLocationIntelligenceAvailable = useSelector(isOilChangersLocationIntelligenceAvailable);
  const locationIntelligenceOnly = useSelector(isLocationIntelligenceOnly);
  const oilAndLocationIntelligenceOnly = useSelector(isOilAndLocationIntelligenceOnly);
  const oilLocationIntelligenceOnlyUser = useSelector(isOilLocationIntelligenceOnly);
  const [reportsOpen, setReportsOpen] = useState(pathname.startsWith('/reports/'));

  const driveoffDashboard = (
    <ListItem button selected={pathname === '/dashboard/driveoff'} component={Link} to="/dashboard/driveoff">
      <ListItemIcon>
        <DriveoffDashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Drive-off dashboard" />
    </ListItem>
  );
  const locationIntelligence = (
    <ListItem button selected={pathname === '/locationintelligence'} component={Link} to="/locationintelligence">
      <ListItemIcon>
        <LocationIntelligenceIcon />
      </ListItemIcon>
      <ListItemText primary="Wash site predictor" />
    </ListItem>
  );
  const oilChangersLocationIntelligence = (
    <ListItem button selected={pathname === '/oclocationintelligence'} component={Link} to="/oclocationintelligence">
      <ListItemIcon>
        <OilChangersLocationIntelligenceIcon />
      </ListItemIcon>
      <ListItemText primary="Lube site predictor" />
    </ListItem>
  );
  const users = (
    <ListItem button selected={pathname === '/users'} component={Link} to="/users">
      <ListItemIcon>
        <UsersIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItem>
  );

  return (
    <Grid className={classes.grow} container direction="column" wrap="nowrap">
      <ImpersonationStatus />
      <Link to="/">
        <Logo className={classes.logo} />
      </Link>

      <Divider />
      <List>
        {oilLocationIntelligenceOnlyUser && oilChangersLocationIntelligence}
        {driveoffDashboardOnly && driveoffDashboardAvailable && !oilLocationIntelligenceOnlyUser && driveoffDashboard}
        {(oilAndLocationIntelligenceOnly || (locationIntelligenceOnly && locationIntelligenceAvailable)) &&
          !oilLocationIntelligenceOnlyUser && (
            <>
              {locationIntelligence}
              {oilAndLocationIntelligenceOnly && oilChangersLocationIntelligence}
              {usersAvailable && <>{users}</>}
            </>
          )}
        {!oilAndLocationIntelligenceOnly &&
          !locationIntelligenceOnly &&
          !driveoffDashboardOnly &&
          !oilLocationIntelligenceOnlyUser && (
            <>
              {companyDashboardAvailable && (
                <ListItem button selected={pathname === '/dashboard/company'} component={Link} to="/dashboard/company">
                  <ListItemIcon>
                    <CompanyDashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Portfolio leaderboards" />
                </ListItem>
              )}
              <ListItem button selected={pathname === '/dashboard/sales'} component={Link} to="/dashboard/sales">
                <ListItemIcon>
                  <SalesDashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Sales insights" />
              </ListItem>
              {opsDashboardAvailable && (
                <ListItem button selected={pathname === '/dashboard/ops'} component={Link} to="/dashboard/ops">
                  <ListItemIcon>
                    <OpsDashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Operations insights" />
                </ListItem>
              )}
              <ListItem button selected={pathname === '/dashboard/camera'} component={Link} to="/dashboard/camera">
                <ListItemIcon>
                  <CameraDashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Camera dashboard" />
              </ListItem>
              {driveoffDashboardAvailable && driveoffDashboard}
              <ListItem button onClick={() => setReportsOpen((state) => !state)}>
                <ListItemIcon>
                  <ReportsIcon />
                </ListItemIcon>
                <ListItemText primary="Analytics" />
                {reportsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    className={classes.nested}
                    button
                    selected={pathname === '/reports/customersbytags'}
                    component={Link}
                    to="/reports/customersbytags"
                  >
                    <ListItemIcon>
                      <CarIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="Total customers" />
                  </ListItem>
                  <ListItem
                    className={classes.nested}
                    button
                    selected={pathname === '/reports/lpsearch'}
                    component={Link}
                    to="/reports/lpsearch"
                  >
                    <ListItemIcon>
                      <LicensePlateSearchIcon />
                    </ListItemIcon>
                    <ListItemText primary="Customer search" />
                  </ListItem>
                  {activeVisitsAvailable && (
                    <ListItem
                      className={classes.nested}
                      button
                      selected={pathname === '/reports/activevisits'}
                      component={Link}
                      to="/reports/activevisits"
                    >
                      <ListItemIcon>
                        <ActiveVisitsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Active visits" />
                    </ListItem>
                  )}
                  {visitDurationAvailable && (
                    <ListItem
                      className={classes.nested}
                      button
                      selected={pathname === '/reports/visitsduration'}
                      component={Link}
                      to="/reports/visitsduration"
                    >
                      <ListItemIcon>
                        <VisitsDurationIcon />
                      </ListItemIcon>
                      <ListItemText primary="Visit duration" />
                    </ListItem>
                  )}
                  {rewashesAvailable && (
                    <ListItem
                      className={classes.nested}
                      button
                      selected={pathname === '/reports/rewashes'}
                      component={Link}
                      to="/reports/rewashes"
                    >
                      <ListItemIcon>
                        <RewashesIcon />
                      </ListItemIcon>
                      <ListItemText primary="Rewashes" />
                    </ListItem>
                  )}
                  {fraudReportAvailable && (
                    <ListItem
                      className={classes.nested}
                      button
                      selected={pathname === '/reports/fraud'}
                      component={Link}
                      to="/reports/fraud"
                    >
                      <ListItemIcon>
                        <FraudIcon />
                      </ListItemIcon>
                      <ListItemText primary="Member validation" />
                    </ListItem>
                  )}
                  {fraudDebugReportAvailable && (
                    <ListItem
                      className={classes.nested}
                      button
                      selected={pathname === '/reports/frauddebug'}
                      component={Link}
                      to="/reports/frauddebug"
                    >
                      <ListItemIcon>
                        <FraudDebugIcon />
                      </ListItemIcon>
                      <ListItemText primary="Member validation debug" />
                    </ListItem>
                  )}
                </List>
              </Collapse>
              {insightsAvailable && (
                <ListItem button selected={pathname === '/insights'} component={Link} to="/insights">
                  <ListItemIcon>
                    <InsightsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <div className={classes.insightsMenuLabelContainer}>
                        <span className={classes.insightsMenuLabel}>Predictive insights</span>
                        <PlusLabel />
                      </div>
                    }
                  />
                </ListItem>
              )}
              <Divider />
              {locationsAvailable && (
                <ListItem button selected={pathname === '/locations'} component={Link} to="/locations">
                  <ListItemIcon>
                    <LocationsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Locations" />
                </ListItem>
              )}
              {companiesAvailable && (
                <ListItem button selected={pathname === '/companies'} component={Link} to="/companies">
                  <ListItemIcon>
                    <DomainIcon />
                  </ListItemIcon>
                  <ListItemText primary="Companies" />
                </ListItem>
              )}
              {usersAvailable && users}
              {!isWhiteLabel && (
                <ListItem
                  onClick={() => mixpanel((mp) => mp.track('omniX API click'))}
                  button
                  component="a"
                  href="https://api.omnixlabs.com"
                  target="_blank"
                  rel="noopener"
                >
                  <ListItemIcon>
                    <ApiIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="omniX API" />
                </ListItem>
              )}
              {locationIntelligenceAvailable && (
                <>
                  <Divider />
                  {locationIntelligence}
                </>
              )}
              {oilChangersLocationIntelligenceAvailable && (
                <>
                  <Divider />
                  {oilChangersLocationIntelligence}
                </>
              )}
            </>
          )}
      </List>

      <Grid className={classes.grow} direction="column" container justify="flex-end">
        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 32, marginBottom: 8 }}
        >
          <Button component="a" href="mailto:support@omnixlabs.com" color="secondary" style={{ textTransform: 'none' }}>
            Contact support
            <ContactSupportIcon className={classes.icon} style={{ marginLeft: 8 }} />
          </Button>
        </div>
        <Divider />
        <UserInfoNavigationMenu />
      </Grid>
    </Grid>
  );
}

function PrimaryNavigation({ classes, theme, location, history }) {
  let [hideNavigationSidebar] = useQueryParam('hideNavigationSidebar', (v) => v ?? 0, location, history);
  if (
    location?.pathname === '/dashboard/iframesalesleaderboard' ||
    location?.pathname === '/dashboard/iframeportfolioleaderboard'
  ) {
    hideNavigationSidebar = 1;
  }
  const [mobileOpen, setMobileOpen] = useState(false);
  const navRef = useRef(null);

  useDidMount(() => {
    if (hideNavigationSidebar > 0 && navRef.current) {
      navRef.current.remove();
    }
  });
  useEffect(() => setMobileOpen(false), [location]);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <>
      <MenuAppBar onMenuClick={handleDrawerToggle} />
      <nav ref={navRef} className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <NavigationDrawer classes={classes} location={location} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <NavigationDrawer classes={classes} location={location} />
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
}

export default withRouter(withStyles(styles, { withTheme: true })(PrimaryNavigation));
