import { FORBIDDEN, UNAUTHORIZED } from 'http-status-codes';
import { fromJS } from 'immutable';
import { cloneDeep, compact, flatten, get, keyBy, map, some, sortBy, uniqBy } from 'lodash';
import { types as networkTypes } from 'reducers/network';
import { getErrorResponseStatus } from 'reducers/utils';
import { AclOperationMap, UserRolesMap } from 'components/common/constants';

export const SW_STRATA_INVESTMENT_GROUP_ID = 'ccee2001-69af-4ec8-9092-a1ba419d6460';
export const NEW_MONTAIN_CAPITAL_COMPANY_ID = '48edd2a2-02d1-44c8-9220-d0f73275b883';
export const EW_COMPANY_ID = 'b2e78fec-2fd9-4217-af26-214643e47c93';
export const ICWG_COMPANY_ID = 'e405defd-af1f-4d96-8970-4f8c3408b533';
export const SGT_CLEAN_COMPANY_ID = '48dd8b3a-5fdc-48eb-841b-4fe94645adff';
export const EZ_CARWASH_COMPANY_ID = '8cef6592-f1b2-422a-82a1-c2591501efe6';
export const SPLASH_COMPANY_ID = '39ba732a-ecb5-471f-adef-7bc4897e3dae';
export const RT_COMPANY_ID = '5f014a90-b4d8-4390-8843-070c0edd3a4a';
export const OIL_CHANGERS_COMPANY_ID = '2a4c9a6b-ce0c-44ee-b275-6b15c35d4429';
export const STOUT_VENTURES_COMPANY_ID = 'ab8237c8-59ba-44ed-ac9f-0c56016c7489';
export const KYLE_CARLISLE_USER_ID = 'bafe9912-d342-41a1-a726-1d3128322a8a';
export const LOCATION_INTELLIGENCE_ONLY_COMPANIES = [
  EZ_CARWASH_COMPANY_ID,
  SW_STRATA_INVESTMENT_GROUP_ID,
  NEW_MONTAIN_CAPITAL_COMPANY_ID,
  'ef6721e5-53bd-4682-8dc3-f65ab438fa58',
];
export const OIL_LOCATION_INTELLIGENCE_ONLY_COMPANIES = [OIL_CHANGERS_COMPANY_ID];
export const OIL_AND_LOCATION_INTELLIGENCE_ONLY_COMPANIES = [STOUT_VENTURES_COMPANY_ID];
export const OIL_LOCATION_INTELLIGENCE_ONLY_USERS = [KYLE_CARLISLE_USER_ID];
export const OPS_DASHBOARD_CONFIG = [
  // {
  //   locationId: 'ea9ef92b-1814-4a24-b4b9-ec94a496f301',
  //   enterTags: ['29'],
  //   exitTags: ['37'],
  //   visitDurationCategories: [5, 10, 15],
  // },
  // {
  //   locationId: '84bba9e7-0a8a-4af5-8b23-f1a16844c7bb',
  //   enterTags: ['25'],
  //   exitTags: ['27'],
  //   visitDurationCategories: [5, 10, 15],
  // },
  {
    locationId: '741100c2-4a1c-45b5-a44f-c315b63aa30b',
    enterTags: ['56'],
    exitTags: ['60'],
    openHour: 6,
    closeHour: 20,
    visitDurationCategories: [5, 10, 15],
  },
  {
    locationId: 'ff42e6f2-0598-4dec-b059-044156f19d6b',
    enterTags: ['68'],
    exitTags: ['69'],
    openHour: 5,
    closeHour: 23,
    visitDurationCategories: [5, 10, 15],
  },
  {
    locationId: 'a0d95fc0-ff2f-47b6-9eed-8c631623f5ec',
    enterTags: ['68'],
    exitTags: ['69'],
    openHour: 7,
    closeHour: 21,
    visitDurationCategories: [5, 10, 15],
  },
  {
    locationId: '7de3ed49-249b-412d-b7c1-af56f4dacfdf',
    enterTags: ['73'],
    exitTags: ['76'],
    openHour: 8,
    closeHour: 19,
    visitDurationCategories: [5, 10, 15],
  },
  {
    locationId: '13f81078-7257-49e3-a678-72b8de7c9948',
    enterTags: ['73'],
    exitTags: ['76'],
    openHour: 8,
    closeHour: 19,
    visitDurationCategories: [5, 10, 15],
  },
  {
    locationId: '08288c44-df1c-43d5-a48d-cea67580e5e0',
    enterTags: ['73'],
    exitTags: ['76'],
    openHour: 7,
    closeHour: 20,
    visitDurationCategories: [5, 10, 15],
  },
  {
    locationId: 'ea9ef92b-1814-4a24-b4b9-ec94a496f301',
    enterTags: ['28'],
    exitTags: ['65'],
    openHour: 7,
    closeHour: 20,
    visitDurationCategories: [5, 10, 15],
  },
  {
    locationId: 'f68bfcb6-24ca-4ec3-a4c1-cd5a31c32b42',
    enterTags: ['35'],
    exitTags: ['39'],
    openHour: 7,
    closeHour: 20,
    visitDurationCategories: [5, 10, 15],
  },
];
export const DRIVE_OFF_CLASSIC_LAYOUT = 'classic'; // drive-off = exit count - bay count
export const DRIVE_OFF_NON_CLASSIC_LAYOUT = 'nonclassic'; // drive-off = exit count
export const DRIVEOFF_DASHBOARD_CONFIG = [
  {
    locationId: '07c7e006-ac21-4c8a-8487-d0488d322b29',
    bayTags: ['66'],
    exitTags: ['67'],
    layout: DRIVE_OFF_CLASSIC_LAYOUT,
  },
  {
    locationId: 'cd619ddb-2e4d-4b19-a5af-1a2817ba320e',
    bayTags: ['66'],
    exitTags: ['67'],
    layout: DRIVE_OFF_CLASSIC_LAYOUT,
  },
  {
    locationId: 'ca5727ed-c11d-498d-a8f1-5b27f39472b7',
    bayTags: ['66'],
    exitTags: ['67'],
    layout: DRIVE_OFF_CLASSIC_LAYOUT,
  },
  {
    locationId: 'e7bc6d3d-e95a-47f8-8f57-08dab8427841',
    bayTags: ['66'],
    exitTags: ['67'],
    layout: DRIVE_OFF_CLASSIC_LAYOUT,
  },
  {
    locationId: '5653c7d3-cbf9-40bf-b011-8c820f06a884',
    bayTags: ['87'],
    exitTags: ['86'],
    layout: DRIVE_OFF_CLASSIC_LAYOUT,
  },
  {
    locationId: 'f69998cd-607a-4ad3-ab83-b002a42b757e',
    bayTags: ['87'],
    exitTags: ['86'],
    layout: DRIVE_OFF_CLASSIC_LAYOUT,
  },
  {
    locationId: 'e297403d-ebcb-4020-85ca-afc69e8e33d7',
    bayTags: ['87'],
    exitTags: ['86'],
    layout: DRIVE_OFF_CLASSIC_LAYOUT,
  },
  {
    locationId: 'c0e7518a-420b-4cdf-a7f4-cc36e5c36160',
    bayTags: ['66'],
    exitTags: ['70'],
    layout: DRIVE_OFF_NON_CLASSIC_LAYOUT,
  },
];
export const SALE_TIME_COMPANY_DASHBOARD_GROUP_NAME = 'Sale time';
export const COMPANY_DASHBOARD_CONFIG = [
  {
    name: 'Wash time',
    locations: [
      { id: 'f46f622f-ee2e-45bf-805c-c9d2dfe3ed05', enterTags: ['35'], exitTags: ['39'] },
      { id: 'fed3356d-597f-49d4-a2c6-aa02e8bb9bae', enterTags: ['35'], exitTags: ['39'] },
      { id: 'ea9ef92b-1814-4a24-b4b9-ec94a496f301', enterTags: ['35'], exitTags: ['39'] },
      { id: '91e981be-3f49-43e8-bf6f-137aeee95747', enterTags: ['35'], exitTags: ['39'] },
      { id: 'f68bfcb6-24ca-4ec3-a4c1-cd5a31c32b42', enterTags: ['35'], exitTags: ['39'] },
      { id: 'd6945e0e-45d9-409d-849d-42f0c97143ce', enterTags: ['35'], exitTags: ['39'] },
      { id: 'aa87947b-2807-43c8-b85c-9136040eed11', enterTags: ['35'], exitTags: ['39'] },
      { id: '419c6448-ecaf-4cfd-8d53-5d1a17f8dfd9', enterTags: ['35'], exitTags: ['39'] },
      { id: 'f42d8ec1-df91-455e-8563-f58438277f7d', enterTags: ['35'], exitTags: ['39'] },
      { id: '9be35584-0535-4b4c-ac72-dc0cb48cfcd2', enterTags: ['35'], exitTags: ['39'] },
      { id: '2273025e-4905-4d02-818c-59baaf0d8977', enterTags: ['35'], exitTags: ['39'] },
      { id: '31df1142-e054-4628-aeb8-c01dc0444651', enterTags: ['35'], exitTags: ['39'] },
      { id: '5728fac5-15af-4c96-a86f-dceaaa7a5a6a', enterTags: ['35'], exitTags: ['39'] },
      { id: '66c20e48-db10-4b46-90b3-2f165381e450', enterTags: ['35'], exitTags: ['39'] },
      { id: '7943aadb-5dc2-4c35-9e69-8f2803ca28ab', enterTags: ['35'], exitTags: ['39'] },
      { id: '2dccb752-f9ef-4905-8ae6-d108257d3efd', enterTags: ['35'], exitTags: ['39'] },
    ],
  },
  {
    name: SALE_TIME_COMPANY_DASHBOARD_GROUP_NAME,
    locations: [
      { id: 'f46f622f-ee2e-45bf-805c-c9d2dfe3ed05', enterTags: ['35'], exitTags: ['35'] },
      { id: 'fed3356d-597f-49d4-a2c6-aa02e8bb9bae', enterTags: ['35'], exitTags: ['35'] },
      { id: 'ea9ef92b-1814-4a24-b4b9-ec94a496f301', enterTags: ['28'], exitTags: ['28'] },
      { id: '91e981be-3f49-43e8-bf6f-137aeee95747', enterTags: ['35'], exitTags: ['35'] },
      { id: 'f68bfcb6-24ca-4ec3-a4c1-cd5a31c32b42', enterTags: ['35'], exitTags: ['35'] },
      { id: 'd6945e0e-45d9-409d-849d-42f0c97143ce', enterTags: ['35'], exitTags: ['35'] },
      { id: 'aa87947b-2807-43c8-b85c-9136040eed11', enterTags: ['35'], exitTags: ['35'] },
      { id: '419c6448-ecaf-4cfd-8d53-5d1a17f8dfd9', enterTags: ['35'], exitTags: ['35'] },
    ],
  },
  {
    name: 'Prep time',
    locations: [
      { id: 'f46f622f-ee2e-45bf-805c-c9d2dfe3ed05', enterTags: ['29'], exitTags: ['29'] },
      { id: 'fed3356d-597f-49d4-a2c6-aa02e8bb9bae', enterTags: ['29'], exitTags: ['29'] },
      { id: 'ea9ef92b-1814-4a24-b4b9-ec94a496f301', enterTags: ['29'], exitTags: ['29'] },
      { id: '91e981be-3f49-43e8-bf6f-137aeee95747', enterTags: ['29'], exitTags: ['29'] },
      { id: 'f68bfcb6-24ca-4ec3-a4c1-cd5a31c32b42', enterTags: ['29'], exitTags: ['29'] },
      { id: 'd6945e0e-45d9-409d-849d-42f0c97143ce', enterTags: ['29'], exitTags: ['29'] },
      { id: 'aa87947b-2807-43c8-b85c-9136040eed11', enterTags: ['29'], exitTags: ['29'] },
      { id: '419c6448-ecaf-4cfd-8d53-5d1a17f8dfd9', enterTags: ['29'], exitTags: ['29'] },
    ],
  },
];

export const types = {
  LOGIN_REQUEST: 'AUTH/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'AUTH/LOGIN_FAILURE',
  LOGOUT: 'AUTH/LOGOUT',
  USER_CONTEXT_UPDATED: 'AUTH/USER_CONTEXT_UPDATED',
  RESET_FLAGS: 'AUTH/RESET_FLAGS',
};

function prepareUserContext(userContext) {
  const { jobs = [], locations: allLocations = [], company, disabledReports } = userContext;
  let { opsDashboardConfig = {}, driveoffDashboardConfig = {}, companies = [] } = userContext;

  companies = sortBy(companies, ({ name }) => name.toLowerCase());
  if (!companies.length && company) {
    companies.push(company);
  }
  companies = cloneDeep(companies);
  companies.forEach((company) => {
    const companyLocations = sortBy(
      allLocations.filter(
        (location) => company.id === get(location, 'company.id'),
        (location) => location.name.toLowerCase()
      )
    );
    company.locations = {
      byId: keyBy(companyLocations, 'id'),
      allIds: map(companyLocations, 'id'),
    };
  });
  userContext.companies = {
    byId: keyBy(companies, 'id'),
    allIds: map(companies, 'id'),
  };

  userContext.jobs = {
    byId: keyBy(jobs, 'id'),
    allIds: map(jobs, 'id'),
  };

  const locations = uniqBy(compact(jobs.map((job) => job.location)), 'id');
  locations.forEach((location) => {
    const tags = compact(
      uniqBy(flatten(jobs.filter((job) => location.id === get(job, 'location.id')).map((job) => job.tags)), 'id')
    );
    location.tags = {
      byId: keyBy(tags, 'id'),
      allIds: map(tags, 'id'),
    };
  });
  userContext.locations = {
    byId: keyBy(locations, 'id'),
    allIds: map(locations, 'id'),
  };

  const locationsWithInsights = locations.filter((location) => location.insightsAccess === true);
  userContext.locationsWithInsights = {
    byId: keyBy(locationsWithInsights, 'id'),
    allIds: map(locationsWithInsights, 'id'),
  };

  const locationsWithVisitDuration = locations.filter((location) => location.visitDurationAvailable === true);
  userContext.locationsWithVisitDuration = {
    byId: keyBy(locationsWithVisitDuration, 'id'),
    allIds: map(locationsWithVisitDuration, 'id'),
  };

  const locationsWithActiveVisits = locations.filter((location) => location.activeVisitsAvailable === true);
  userContext.locationsWithActiveVisits = {
    byId: keyBy(locationsWithActiveVisits, 'id'),
    allIds: map(locationsWithActiveVisits, 'id'),
  };

  const locationsWithRewashes = locations.filter((location) => location.enterLaneJobIds?.length > 0);
  userContext.locationsWithRewashes = {
    byId: keyBy(locationsWithRewashes, 'id'),
    allIds: map(locationsWithRewashes, 'id'),
  };

  const locationsWithFraud = locations.filter(
    (location) =>
      location.company?.id === EW_COMPANY_ID ||
      location.whitelabeler?.id === EW_COMPANY_ID ||
      location.company?.id === SGT_CLEAN_COMPANY_ID ||
      location.company?.id === SPLASH_COMPANY_ID
  );
  userContext.locationsWithFraud = {
    byId: keyBy(locationsWithFraud, 'id'),
    allIds: map(locationsWithFraud, 'id'),
  };

  const locationsWithSales = locations.filter(
    (location) =>
      location.company?.id === ICWG_COMPANY_ID ||
      location.company?.id === SGT_CLEAN_COMPANY_ID ||
      location.company?.id === SPLASH_COMPANY_ID ||
      location.company?.id === EW_COMPANY_ID ||
      location.whitelabeler?.id === EW_COMPANY_ID
  );
  userContext.locationsWithSales = {
    byId: keyBy(locationsWithSales, 'id'),
    allIds: map(locationsWithSales, 'id'),
  };

  opsDashboardConfig = parseDashboardConfig(opsDashboardConfig, OPS_DASHBOARD_CONFIG);
  const locationsWithOps = locations.filter((location) =>
    some(opsDashboardConfig, (conf) => conf.locationId === location.id)
  );
  userContext.opsDashboardConfig = opsDashboardConfig;
  userContext.locationsWithOps = {
    byId: keyBy(locationsWithOps, 'id'),
    allIds: map(locationsWithOps, 'id'),
  };

  driveoffDashboardConfig = parseDashboardConfig(driveoffDashboardConfig, DRIVEOFF_DASHBOARD_CONFIG);
  const locationsWithDriveoff = locations.filter((location) =>
    some(driveoffDashboardConfig, (conf) => conf.locationId === location.id)
  );
  userContext.driveoffDashboardConfig = driveoffDashboardConfig;
  userContext.locationsWithDriveoff = {
    byId: keyBy(locationsWithDriveoff, 'id'),
    allIds: map(locationsWithDriveoff, 'id'),
  };
  userContext.disabledReports = disabledReports;

  return fromJS(userContext);
}

function parseDashboardConfig(config, fallback) {
  const { value = [] } = config;
  if (!value?.length) {
    return fallback;
  }
  return value;
}

export const initialState = fromJS({
  userContext: null,
  isFetching: false,
  error: null,
});

export default function auth(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return state.withMutations((s) => s.set('isFetching', true).set('error', null));
    case types.LOGIN_SUCCESS:
      return state.withMutations((s) =>
        s.set('isFetching', false).set('userContext', prepareUserContext(action.userContext))
      );
    case types.LOGIN_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        if (getErrorResponseStatus(action) === UNAUTHORIZED) {
          s.set('error', 'Incorrect email or password');
        }
      });
    case types.LOGOUT:
      return state.set('userContext', null);
    case types.USER_CONTEXT_UPDATED:
      return state.set('userContext', prepareUserContext(action.userContext));
    case types.RESET_FLAGS:
      return state.withMutations((s) => s.set('isFetching', false).set('error', null));
    case networkTypes.REQUEST_FAILURE: {
      const status = getErrorResponseStatus(action);
      const isFetching = state.get('isFetching');
      if (!isFetching && (status === UNAUTHORIZED || status === FORBIDDEN)) {
        return initialState;
      }
      return state;
    }
    default:
      return state;
  }
}

export const actions = {
  loginRequest: (email, password, rememberMe) => ({ type: types.LOGIN_REQUEST, email, password, rememberMe }),
  loginSuccess: (userContext) => ({ type: types.LOGIN_SUCCESS, userContext }),
  loginFailure: (error) => ({ type: types.LOGIN_FAILURE, error }),
  logout: () => ({ type: types.LOGOUT }),
  userContextUpdated: (userContext) => ({ type: types.USER_CONTEXT_UPDATED, userContext }),
  resetFlags: () => ({ type: types.RESET_FLAGS }),
};

export const getAuth = (state) => state.get('auth');
export const getUserContext = (state) => getAuth(state).get('userContext');
export const isAuthenticated = (state) => getUserContext(state) !== null;
export const isImpersonation = (state) =>
  isAuthenticated(state) && getUserContext(state).get('originalUser') !== undefined;
export const getUserEmail = (state) => (getUserContext(state) ? getUserContext(state).get('email') : null);
export const getUserName = (state) => (getUserContext(state) ? getUserContext(state).get('name') : null);
export const getUserCompanyName = (state) =>
  getUserContext(state) ? getUserContext(state).getIn(['company', 'name']) : null;
export const getUserCompanyId = (state) =>
  getUserContext(state) ? getUserContext(state).getIn(['company', 'id']) : null;
export const getUserWhitelabelerId = (state) =>
  getUserContext(state) ? getUserContext(state).getIn(['whitelabeler', 'id']) : null;
export const getUserTimezone = (state) => (getUserContext(state) ? getUserContext(state).get('timezone') : null);
export const getJobs = (state) => state.getIn(['auth', 'userContext', 'jobs', 'byId']);
export const getJobsIds = (state) => state.getIn(['auth', 'userContext', 'jobs', 'allIds']);
export const getDefaultJobId = (state) => {
  const jobs = getJobsIds(state);
  if (!jobs || jobs.isEmpty()) {
    return null;
  }

  const lastLocationId = localStorage.getItem('lastUsedLocationId');
  const lastUsed = localStorage.getItem('lastUsedJobId');
  if (
    lastUsed &&
    jobs.includes(lastUsed) &&
    (!lastLocationId || lastLocationId === getLocationIdByJobId(state, lastUsed))
  ) {
    return lastUsed;
  }
  if (lastLocationId) {
    const allJobs = getJobs(state);
    const job = allJobs.find((jobValue) => jobValue.getIn(['location', 'id']) === lastLocationId);
    if (job) {
      return job.get('id');
    }
  }

  return jobs.first();
};
export const getJobName = (state, jobId) => {
  const locations = getJobs(state);
  return locations.getIn([jobId, 'name']);
};
export const getLocationIdByJobId = (state, jobId) => {
  const locations = getJobs(state);
  return locations.getIn([jobId, 'location', 'id']);
};
export const getLocations = (state) => state.getIn(['auth', 'userContext', 'locations', 'byId']);
export const getLocationName = (state, locationId) => {
  const locations = getLocations(state);
  return locations.getIn([locationId, 'name']);
};
export const getTagName = (state, locationId, tagId) => {
  const locations = getLocations(state);
  return locations.getIn([locationId, 'tags', 'byId', tagId, 'name']);
};
export const getLocationIds = (state) => state.getIn(['auth', 'userContext', 'locations', 'allIds']);
export const getDefaultLocationId = (state) => {
  const locations = getLocationIds(state);
  if (!locations || locations.isEmpty()) {
    return null;
  }

  const lastUsed = localStorage.getItem('lastUsedLocationId');
  if (lastUsed && locations.includes(lastUsed)) {
    return lastUsed;
  }

  return locations.first();
};
export const getLocationsWithInsights = (state) =>
  state.getIn(['auth', 'userContext', 'locationsWithInsights', 'byId']);
export const getLocationIdsWithInsights = (state) =>
  state.getIn(['auth', 'userContext', 'locationsWithInsights', 'allIds']);
export const getDefaultLocationIdWithInsights = (state) => {
  const locations = getLocationIdsWithInsights(state);
  if (!locations || locations.isEmpty()) {
    return null;
  }

  const lastUsed = localStorage.getItem('lastUsedLocationId');
  if (lastUsed && locations.includes(lastUsed)) {
    return lastUsed;
  }

  return locations.first();
};
export const getDisabledReports = (state) => getUserContext(state).get('disabledReports') ?? [];
export const isInsightsAvailable = (state) => {
  const locations = getLocationsWithInsights(state);
  return locations && !locations.isEmpty();
};
export const getLocationsWithVisitDuration = (state) =>
  state.getIn(['auth', 'userContext', 'locationsWithVisitDuration', 'byId']);
export const getLocationIdsWithVisitDuration = (state) =>
  state.getIn(['auth', 'userContext', 'locationsWithVisitDuration', 'allIds']);
export const getDefaultLocationIdWithVisitDuration = (state) => {
  const locations = getLocationIdsWithVisitDuration(state);
  if (!locations || locations.isEmpty()) {
    return null;
  }

  const lastUsed = localStorage.getItem('lastUsedLocationId');
  if (lastUsed && locations.includes(lastUsed)) {
    return lastUsed;
  }

  return locations.first();
};
export const isVisitDurationAvailable = (state) => {
  const locations = getLocationsWithVisitDuration(state);
  return locations && !locations.isEmpty();
};
export const getLocationsWithActiveVisits = (state) =>
  state.getIn(['auth', 'userContext', 'locationsWithActiveVisits', 'byId']);
export const getLocationIdsWithActiveVisits = (state) =>
  state.getIn(['auth', 'userContext', 'locationsWithActiveVisits', 'allIds']);
export const getDefaultLocationIdWithActiveVisits = (state) => {
  const locations = getLocationIdsWithActiveVisits(state);
  if (!locations || locations.isEmpty()) {
    return null;
  }

  const lastUsed = localStorage.getItem('lastUsedLocationId');
  if (lastUsed && locations.includes(lastUsed)) {
    return lastUsed;
  }

  return locations.first();
};
export const isActiveVisitsAvailable = (state) => {
  const locations = getLocationsWithActiveVisits(state);
  return locations && !locations.isEmpty();
};
export const getLocationsWithRewashes = (state) =>
  state.getIn(['auth', 'userContext', 'locationsWithRewashes', 'byId']);
export const getLocationIdsWithRewashes = (state) =>
  state.getIn(['auth', 'userContext', 'locationsWithRewashes', 'allIds']);
export const getDefaultLocationIdWithRewashes = (state) => {
  const locations = getLocationIdsWithRewashes(state);
  if (!locations || locations.isEmpty()) {
    return null;
  }

  const lastUsed = localStorage.getItem('lastUsedLocationId');
  if (lastUsed && locations.includes(lastUsed)) {
    return lastUsed;
  }

  return locations.first();
};
export const isRewashesAvailable = (state) => {
  const locations = getLocationsWithRewashes(state);
  if (!locations || locations.isEmpty()) {
    return false;
  }
  return locations && !locations.isEmpty();
};
export const getLocationsWithFraud = (state) => state.getIn(['auth', 'userContext', 'locationsWithFraud', 'byId']);
export const getLocationIdsWithFraud = (state) => state.getIn(['auth', 'userContext', 'locationsWithFraud', 'allIds']);
export const getDefaultLocationIdWithFraud = (state) => {
  const locations = getLocationIdsWithFraud(state);
  if (!locations || locations.isEmpty()) {
    return null;
  }

  const lastUsed = localStorage.getItem('lastUsedLocationId');
  if (lastUsed && locations.includes(lastUsed)) {
    return lastUsed;
  }

  return locations.first();
};
export const isFraudReportAvailable = (state) => {
  const locations = getLocationsWithFraud(state);
  const disabledReports = getDisabledReports(state);

  if (!locations || locations.isEmpty()) {
    return false;
  }

  const role = getUserRoleName(state);

  return (
    isAdmin(state) || (role === UserRolesMap.COMPANY_ADMIN && !disabledReports.includes('memberValidationFraudReport'))
  );
};
export const isFraudDebugReportAvailable = (state) => {
  const companyId = getUserCompanyId(state);
  const locations = getLocationsWithFraud(state);
  if (!locations || locations.isEmpty()) {
    return false;
  }
  const role = getUserRoleName(state);
  return isAdmin(state) || (role === UserRolesMap.COMPANY_ADMIN && companyId === EW_COMPANY_ID);
};
export const getLocationsWithSales = (state) => state.getIn(['auth', 'userContext', 'locationsWithSales', 'byId']);
export const getLocationIdsWithSales = (state) => state.getIn(['auth', 'userContext', 'locationsWithSales', 'allIds']);
export const getDefaultLocationIdWithSales = (state) => {
  const locations = getLocationIdsWithSales(state);
  if (!locations || locations.isEmpty()) {
    return null;
  }

  const lastUsed = localStorage.getItem('lastUsedLocationId');
  if (lastUsed && locations.includes(lastUsed)) {
    return lastUsed;
  }

  return locations.first();
};
export const getLocationsWithOps = (state) => state.getIn(['auth', 'userContext', 'locationsWithOps', 'byId']);
export const getLocationIdsWithOps = (state) => state.getIn(['auth', 'userContext', 'locationsWithOps', 'allIds']);
export const getDefaultLocationIdWithOps = (state) => {
  const locations = getLocationIdsWithOps(state);
  if (!locations || locations.isEmpty()) {
    return null;
  }

  const lastUsed = localStorage.getItem('lastUsedLocationId');
  if (lastUsed && locations.includes(lastUsed)) {
    return lastUsed;
  }

  return locations.first();
};
export const isOpsDashboardAvailable = (state) => {
  const locations = getLocationsWithOps(state);
  return locations && !locations.isEmpty();
};
export const getLocationsWithDriveoff = (state) =>
  state.getIn(['auth', 'userContext', 'locationsWithDriveoff', 'byId']);
export const getLocationIdsWithDriveoff = (state) =>
  state.getIn(['auth', 'userContext', 'locationsWithDriveoff', 'allIds']);
export const getDefaultLocationIdWithDriveoff = (state) => {
  const locations = getLocationIdsWithDriveoff(state);
  if (!locations || locations.isEmpty()) {
    return null;
  }

  const lastUsed = localStorage.getItem('lastUsedLocationId');
  if (lastUsed && locations.includes(lastUsed)) {
    return lastUsed;
  }

  return locations.first();
};
export const isDriveoffDashboardAvailable = (state) => {
  const locations = getLocationsWithDriveoff(state);
  return locations && !locations.isEmpty();
};
export const isDriveoffDashboardOnly = () => {
  return false;
  // const role = getUserRoleName(state);
  // return isDriveoffDashboardAvailable(state) && role === 'location_manager';
};
export const isLocationIntelligenceAvailable = (state) => {
  const companyId = getUserCompanyId(state);
  return isAdmin(state) || LOCATION_INTELLIGENCE_ONLY_COMPANIES.includes(companyId);
};
export const isLocationIntelligenceOnly = (state) => {
  const companyId = getUserCompanyId(state);
  return LOCATION_INTELLIGENCE_ONLY_COMPANIES.includes(companyId);
};
export const isOilChangersLocationIntelligenceAvailable = (state) => {
  const role = getUserRoleName(state);
  const companyId = getUserCompanyId(state);
  return (
    isAdmin(state) ||
    (OIL_LOCATION_INTELLIGENCE_ONLY_COMPANIES.includes(companyId) && role === UserRolesMap.COMPANY_ADMIN)
  );
};
export const getUserId = (state) => (getUserContext(state) ? getUserContext(state).get('id') : null);
export const getUserRole = (state) => (getUserContext(state) ? getUserContext(state).get('role') : null);
export const getUserRoleName = (state) => (getUserRole(state) ? getUserRole(state).get('name') : null);
export const isUsersAvailable = (state) => {
  const userRole = getUserRole(state);
  return !userRole || userRole.get('authorizedOperations').has('USER');
};
export const isUsersCreateAuthorized = (state) => {
  const userRole = getUserRole(state);

  return !userRole || userRole.get('authorizedOperations').get('USER').includes(AclOperationMap.CREATE);
};
export const isCompaniesAvailable = (state) => {
  const userRole = getUserRole(state);

  return !userRole || userRole.get('authorizedOperations').has('COMPANY');
};
export const isLocationsAvailable = (state) => {
  const userRole = getUserRole(state);
  return !userRole || userRole.get('authorizedOperations').has('LOCATION');
};
export const getCompanies = (state) => state.getIn(['auth', 'userContext', 'companies', 'byId']);
export const getCompanyIds = (state) => state.getIn(['auth', 'userContext', 'companies', 'allIds']);
export const isSuperAdmin = (state) => (getUserContext(state) ? getUserContext(state).get('role') === null : false);
export const isAdmin = (state) => isSuperAdmin(state) || getUserRoleName(state) === UserRolesMap.PORTFOLIO_SUPERVISOR;
export const getOpsDashboardConfig = (state) =>
  getUserContext(state) ? getUserContext(state).get('opsDashboardConfig') : null;
export const getDriveoffDashboardConfig = (state) =>
  getUserContext(state) ? getUserContext(state).get('driveoffDashboardConfig') : null;
export const isCompanyDashboardAvailable = (state) => {
  const companyId = getUserCompanyId(state);
  return isAdmin(state) || SGT_CLEAN_COMPANY_ID === companyId;
};
export const isOilAndLocationIntelligenceOnly = (state) => {
  const role = getUserRoleName(state);
  const companyId = getUserCompanyId(state);

  return OIL_AND_LOCATION_INTELLIGENCE_ONLY_COMPANIES.includes(companyId) && role === UserRolesMap.COMPANY_ADMIN;
};
export const isOilLocationIntelligenceOnly = (state) => {
  const userId = getUserId(state);

  return OIL_LOCATION_INTELLIGENCE_ONLY_USERS.includes(userId);
};
